<template>
  <div>
    <p>
      LEGO® Universe is built to let players safely choose how they would like to socialize in the gameworld.
    </p>
    <!-- picE6AE20195A43017E0B77BDEBE41ECEB7.jpg -->
    <img class="rounded leftimage w-100 h-125" alt="" src="@/assets/news-network/create-your-lu-social-style.jpg">
    <p>
      Daniel Witenberg empowers this creative LEGO fun by helping design the in-game social systems. The LEGO Universe News Network recently spoke to Daniel about a few of the game’s features!
    </p>
    <p>
      <strong>Hi Daniel! How about some new info on how friends can connect in LEGO Universe?</strong>
    </p>
    <p><em>
      Hello, and sure thing! To start with, LEGO Universe players can become either <strong>Friends</strong>
      or <strong>Best Friends.</strong> The main difference between the two is in the available chat options.
    </em></p>
    <p><em>
      Players can use <strong>MiniChat</strong> to communicate with Friends they meet in LEGO Universe. The <strong>MiniChat</strong> system permits players to use only words that are in a LEGO dictionary.
    </em></p>
    <p><em>
      By becoming <strong>Best Friends</strong>, LEGO Universe players will gain more freedom to share content with people they know in real life--folks like your brothers, sisters and friends from school.
    </em></p>
    <p><em>
      <strong>Best Friends</strong> are allowed to communicate using the LEGO Universe <strong>MegaChat</strong> system.
    </em></p>
    <!-- pic1C03C4E4A88A0E12325E574C88200712.jpg -->
    <img class="rounded" src="@/assets/news-network/create-your-lu-social-style-2.jpg">
    <p>
      <strong>How else can friends connect in LEGO Universe?</strong>
    </p>
    <p><em>
      Another LEGO Universe feature that I can reveal right now is that players can form teams to accomplish challenging missions!
    </em></p>
    <p>
      <strong>Can you give us a sneak peek at a LEGO Universe team mission?<br></strong>
    </p>
    <p><em>
      Imagine that a huge and ruthless Maelstrom infected creature has spread chaos throughout part of LEGO Universe. The Stromling is far too powerful for a single minifig to overcome!
    </em></p>
    <p><em>
      Pierce the dark creature’s defenses by forming a team with up to three other players. Winning teams collect loads of loot, and the best teams gain status on the LEGO Universe leaderboards!
    </em></p>
    <!-- pic3F1E6E94B49862EC122BDAF10B509725.jpg -->
    <img class="rounded" src="@/assets/news-network/create-your-lu-social-style-3.jpg">
    <p>
      <strong>Tell us more!</strong>
    </p>
    <p><em>
      I’m not allowed to say more yet, but I’d be happy to come back soon with new details!
    </em></p>
    <p>
      <strong>Thanks Daniel! The LEGO Universe News Network looks forward to it!</strong>
    </p>
    <p>
      Lock your web browser here to get the latest on how friends can safely socialize in the gameworld!<br>
      <span>Part one of <i>
        <router-link :to="{ name: 'story', params: {id: 150524} }">Connect in LEGO® Universe!</router-link>
      </i></span>
      also features more details!
    </p>
  </div>
</template>
